import React from "react"
import Layout from "../components/layout"

const Success = () => {
  return (
    <Layout>
      <div className="h-screen gradient py-24">
        <div className="mx-auto shadow-custom p-8 rounded bg-white w-4/5 mx-auto">
          <h2 className="text-center">Success!</h2>
          <p className="w-4/5">
            Thank you for purchase! <br /> We will send you an email within the
            next <code> 24 Hours </code>
            with a secret link to the early access page where you can create an
            account and start using explot.
          </p>
          <p>
            <i>
              Make sure to double check your spam or trash inbox for our e-mail
              so it doesn't get lost. It will allways come from the @explot (or
              @stripe) domain.
            </i>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Success
